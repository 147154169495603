export default {
    checkMapsLoaded(callback) {
        if (!window.google) {
            window.initMap = callback;
            let script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS}&libraries=geometry&callback=initMap`;
            document.head.appendChild(script);
        } else {
            callback();
        }
    }
}