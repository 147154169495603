<template>
  <Language class="login__language"></Language>
  <img class="login__logo" src="../assets/image/logo-billo-work.svg" alt="logo Billo 2 work">
  <div class="login">
    <h1>{{ $t('login.title') }}</h1>
    <form @submit.prevent="submitForm">
      <div>
        <label for="username">{{ $t('login.username') }}</label>
        <input class="input input--login" :placeholder="$t('login.username')" type="text" id="username" v-model.trim="username" />
      </div>
      <div>
        <label for="password">{{ $t('login.password') }}</label>
        <input class="input input--login" :placeholder="$t('login.password')"  type="password" id="password" v-model.trim="password" />
      </div>
      <button class="button">{{ $t('login.title') }}</button> <router-link to="/password-request">{{$t('passwordrequest.title')}}?</router-link>
    </form>
    <p class="error" v-if="!formIsValid">{{ $t('error.login.invalid') }}</p>
    <p class="error" v-if="error">{{error}}</p>
  </div>
</template>

<script>
import Language from "@/components/Language";
export default {
  name: "Login",

  components:{
    Language
  },

  data() {
    return {
      username: '',
      password: '',
      formIsValid: true,
      isLoading: false,
      error: null
    }
  },

  methods: {
    async submitForm() {
      this.error = null;
      this.formIsValid = true;
      if(!this.username || this.password.length < 4){
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;
      const payload = {
        username: this.username,
        password: this.password
      };
      try{
        await this.$store.dispatch('login', payload);
        this.$router.replace('/');
      }
      catch (err){
        this.error = err.message || this.$t('error.login.failed');
      }
    }
  }
}
</script>