<template>
  <Language class="login__language"></Language>
  <img class="login__logo" src="../assets/image/logo-billo-work.svg" alt="logo Billo 2 work">
  <div class="login">
    <h1>{{ $t('passwordreset.title') }}</h1>
    <form @submit.prevent="submitForm" v-if="validRequest">
      <div>
        <label for="password">{{ $t('login.password') }}</label>
        <input class="input input--login" :placeholder="$t('login.password')" type="password" id="password"
               v-model.trim="password"/>
      </div>
      <div>
        <label for="password2">{{ $t('passwordreset.confirm') }}</label>
        <input class="input input--login" :placeholder="$t('login.password')" type="password" id="password2"
               v-model.trim="password2"/>
      </div>
      <button class="button">{{ $t('passwordreset.request') }}</button>
    </form>
    <p v-else>{{$t('passwordreset.expired')}} <router-link to="login">{{$t('login.title')}}</router-link></p>
    <p class="error" v-if="!formIsValid">{{ $t('error.passwordreset.invalid') }}</p>
    <p class="error" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import Language from "@/components/Language";

export default {
  name: "PasswordReset",
  components: {
    Language
  },
  data() {
    return {
      validRequest: true,
      error: null,
      password: "",
      password2: "",
      formIsValid: true
    };
  },
  created(){
    if(!this.$route.query.code || ! this.$route.query.username){
      this.$router.replace('/login');
      return;
    }
    this.checkRequest();
  },
  methods:{
    async checkRequest(){
      try{
        await this.$store.dispatch('checkPasswordResetValid',{username:this.$route.query.username, code:this.$route.query.code});
        this.validRequest = true;
      } catch (e){
        this.validRequest = false;
      }
    },
    async submitForm(){
      this.formIsValid = this.password && this.password.length >=0 && this.password === this.password2;
      if(!this.formIsValid){
        return;
      }
      try{
        await this.$store.dispatch('editPassword',{username:this.$route.query.username,password:this.password,code:this.$route.query.code});
      } catch (error){
        this.error = error;
      }
    }
  }
}
</script>