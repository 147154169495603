export default {
    locations(state) {
        return state.locations;
    },

    userData(state){
        return state.userData;
    },

    hasLocations(state) {
        return state.locations && state.locations.length;
    }
};