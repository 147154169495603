export default {
    setLocations(state, payload){
        state.locations = payload;
    },
    setUserData(state, payload){
        state.userData = payload;
    },
    setFetchTimestamp(state){
        state.lastFetch = new Date().getTime();
    }
}