<template>
  <div class="google-route"></div>
</template>

<script>
import MapsPlugin from '@/plugins/maps-plugin';
export default {
  name: "GoogleMarker",
  props: {
    data: {required: true}
  },
  mounted() {
    MapsPlugin.checkMapsLoaded(this.initMap)
  },
  methods: {
    initMap() {
      const data = this.data;
      const latlng = {lat: parseFloat(data.lat), lng: parseFloat(data.lon)}
      const map = new google.maps.Map(this.$el, {
        center: latlng,
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      const marker = new google.maps.Marker({
        position: latlng,
        title: data.naam,
        map
      });
      const contentString = `<p><b>${data.naam}</b></p><p>${data.straat} ${data.nummer}<br>${data.postnr} ${data.stad}</p>`;
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    }
  }
}
</script>