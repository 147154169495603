export default {
    async loadEmployees(context) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/listusers?user_id=${userId}`;
        const response = await fetch(url,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user employees.'
            );
            throw error;
        }
        context.commit('setEmployees',responseData.data);
    },

    async addEmployee(context, data) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;

        data.user_id=userId;

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/adduser`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to save new employee.'
            );
            throw error;
        }
    },

    async editEmployee(context,data) {
        const token = context.rootGetters.token;

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/edituser`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit employee.'
            );
            throw error;
        }
    },

    async deleteEmployee(context, data) {
        const token = context.rootGetters.token;

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/deluser`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to delete Employee.'
            );
            throw error;
        }
    },

    async uploadEmployeeList(context,data){
        const token = context.rootGetters.token;
        data.user_id = context.rootGetters.userId;
        const formData = new FormData();
        Object.keys(data).forEach( key => {
            formData.append(key, data[key]);
        });

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/importfile`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body: formData
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to import Employee List.'
            );
            throw error;
        }
        return responseData.data;
    },

    async sendPasswordEmployee(context,data){
        const token = context.rootGetters.token;

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/initpassword/${data}`;
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'Failed to send password reset link.'
            );
            throw error;
        }
        return responseData.status;
    }
}