<template>
  <div class="page employees">
    <h1>{{ $t('employees.title') }}</h1>
    <div class="employees__controls">
      <label for="search">{{$t('search')}}</label><input type="text" class="input" v-model="search">
      <button class="button button--accept" @click="newEmployee">{{ $t('employees.add-employee') }} +</button>
      <button class="button" @click="uploadEmployeeList">{{$t('employees.upload-list')}}</button>
      <a :href="`${publicPath}employees_template.xlsx`">{{ $t('employees.download') }}</a>
    </div>
    <div class="employees__results">
      <teleport to="#app">
        <div class="modal" v-if="isLoading">
          <fa icon="spinner" class="loader"></fa>
        </div>
      </teleport>
      <table class="table" v-if="hasEmployees && filteredEmployees.length">
        <thead>
        <tr class="table__header">
          <th class="table__header__column">{{ $t('actions') }}</th>
          <th class="table__header__column">{{ $t('name') }}</th>
          <th class="table__header__column">{{ $t('firstname') }}</th>
          <th class="table__header__column">{{ $t('login.username') }}</th>
          <template v-if="hasInvoices">
          <th class="table__header__column" >{{$t('company.fact')}}</th>
          <th class="table__header__column" >REF</th>
          </template>
          <th class="table__header__column">{{ $t('email') }}</th>
          <th class="table__header__column">{{ $t('tel') }}</th>
          <th class="table__header__column" v-if="companySettings.employeeaddresses">{{ $t('street') }}</th>
          <th class="table__header__column" v-if="companySettings.employeeaddresses">{{ $t('nr') }}</th>
          <th class="table__header__column" v-if="companySettings.employeeaddresses">{{ $t('zip') }}</th>
          <th class="table__header__column" v-if="companySettings.employeeaddresses">{{ $t('city') }}</th>
          <th class="table__header__column" v-if="companySettings.employeeaddresses">{{ $t('country') }}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr class="table__body__item" v-for="item in filteredEmployees" :key="item.id">
          <td class="table__body__item__column">
            <button class="svgbutton info" :class="{'add':isSent===item.id}" :title="$t('employees.send-employee')" @click="sendMail(item)">
              <fa icon="key"></fa>
            </button>
            <button class="svgbutton info" :title="$t('employees.edit-employee')" @click="editEmployee(item)">
              <fa icon="edit"></fa>
            </button>
            <button class="svgbutton close" :title="$t('employees.delete-employee')" @click="deleteEmployee(item.id)">
              <fa icon="trash-alt"></fa>
            </button>
          </td>
          <td class="table__body__item__column">{{ item.lastname }}</td>
          <td class="table__body__item__column">{{ item.firstname }}</td>
          <td class="table__body__item__column">{{ item.username }}</td>
          <template v-if="hasInvoices">
          <td class="table__body__item__column">{{ item.invoicecompany }}</td>
          <td class="table__body__item__column">{{ item.ext_ref }}</td>
          </template>
          <td class="table__body__item__column">{{ item.email }}</td>
          <td class="table__body__item__column">{{ item.tel }}</td>
          <td class="table__body__item__column" v-if="companySettings.employeeaddresses">{{ item.street }}</td>
          <td class="table__body__item__column" v-if="companySettings.employeeaddresses">{{ item.nr }}</td>
          <td class="table__body__item__column" v-if="companySettings.employeeaddresses">{{ item.zip }}</td>
          <td class="table__body__item__column" v-if="companySettings.employeeaddresses">{{ item.city }}</td>
          <td class="table__body__item__column" v-if="companySettings.employeeaddresses">{{ item.country }}</td>
        </tr>
        </tbody>
      </table>
      <div v-else class="no_results">
        {{ $t('employees.no-employees') }}
      </div>
    </div>
    <teleport to="#app">
      <div class="modal" v-if="isModalActive">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="closeModal">
            <fa icon="times"></fa>
          </button>
          <template v-if="deleteId">
            <div class="modal__title">{{ $t('employees.delete-title') }}</div>
            <div class="modal__body">{{ $t('employees.delete-text') }}</div>
            <div class="modal__controls">
              <button class="button button--cancel" :class="{'button--disabled':isLoading}" :disabled="isLoading"
                      @click="deleteConfirm">{{ $t('delete') }}
              </button>
            </div>
          </template>
          <template v-else>
            <div class="modal__title">{{ employee.id ? $t('employees.edit-title') : $t('employees.add-title') }}</div>
            <div class="modal__body">
              <form action="" @submit.prevent="saveEmployee">
                <div class="form__column">
                  <div class="form__field">
                    <label for="e-fname">{{ $t('firstname') }}</label>
                    <input name="e-fname" :placeholder="$t('firstname')" id="e-fname" type="text" class="input"
                           v-model="employee.firstname">
                  </div>
                  <div class="form__field">
                    <label for="e-name">{{ $t('name') }}</label>
                    <input name="e-name" :placeholder="$t('name')" id="e-name" type="text" class="input"
                           v-model="employee.lastname">
                  </div>
                  <template v-if="companySettings.employeeaddresses">
                  <div class="form__field">
                    <label for="e-street">{{ $t('street') }}</label>
                    <input name="e-street" :placeholder="$t('street')" id="e-street" type="text" class="input"
                           v-model="employee.street">
                  </div>
                  <div class="form__field">
                    <label for="e-nummer">{{ $t('nr') }}</label>
                    <input name="e-nummer" :placeholder="$t('nr')" id="e-nummer" type="text" class="input"
                           v-model="employee.nr">
                  </div>
                  <div class="form__field">
                    <label for="e-postnr">{{ $t('zip') }}</label>
                    <input name="e-postnr" :placeholder="$t('zip')" id="e-postnr" type="text" class="input"
                           v-model="employee.zip">
                  </div>
                  <div class="form__field">
                    <label for="e-stad">{{ $t('city') }}</label>
                    <input name="e-stad" :placeholder="$t('city')" id="e-stad" type="text" class="input"
                           v-model="employee.city">
                  </div>
                  <div class="form__field">
                    <label for="e-land">{{ $t('country') }}</label>
                    <select name="e-land" id="e-land" class="select" v-model="employee.country">
                      <option value="BE">Belgium</option>
                      <option value="FR">France</option>
                      <option value="NL">Netherlands</option>
                      <option value="LU">Luxembourg</option>
                      <option value="DE">Germany</option>
                    </select>
                  </div>
                  </template>
                </div>
                <div class="form__column">
                  <!--<div class="form__field">
                    <label for="e-username">{{ $t('login.username') }}</label>
                    <input name="e-username" :placeholder="$t('login.username')" id="e-username" type="text" class="input"
                           v-model="employee.username">
                  </div>-->
                  <div class="form__field">
                    <label for="e-email">{{ $t('email') }}</label>
                    <input name="e-email" :placeholder="$t('email')" id="e-email" type="email" class="input"
                           v-model="employee.email">
                  </div>
                  <div class="form__field">
                    <label for="e-tel">{{ $t('tel') }}</label>
                    <input name="e-tel" :placeholder="$t('tel')" id="e-tel" type="tel" class="input"
                           v-model="employee.tel">
                  </div>
                  <template v-if="hasInvoices">
                  <div class="form__field" >
                    <label for="e-fact">{{$t('company.fact')}}</label>
                    <select name="e-fact" id="e-fact" class="select" v-model="employee.invoice_id">
                      <option :value="0" disabled>-- {{$t('company.fact')}} --</option>
                      <option v-for="item in invoices" :key="`fact--${item.id}`" :value="item.id">{{item.company}}</option>
                    </select>
                  </div><div class="form__field">
                    <label for="e-ref">Ref</label>
                    <input type="text" class="input" v-model="employee.ext_ref">
                  </div>
                  </template>
                </div>
              </form>
            </div>
            <div class="modal__controls">
              <p class="error" v-if="modalError">{{ modalError }}</p>
              <button class="button button--accept" :class="{'button--disabled':isLoading}" :disabled="isLoading"
                      @click.prevent="saveEmployee">{{ $t('save') }}
              </button>
            </div>
          </template>
        </div>
      </div>
      <div class="modal" v-if="isUploadActive">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="closeModal">
            <fa icon="times"></fa>
          </button>
          <div class="modal__title">{{ $t('employees.upload-list') }}</div>
          <div class="modal__body">
            <div class="modal__loader" v-if="isLoading">
              <fa icon="spinner" class="loader"></fa>
            </div>
            <template v-else>
              <table class="table" v-if="uploadListResult">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column" v-if="uploadListResult && modalError">errors</th>
                  <th class="table__header__column" v-if="uploadListResult && modalError">error description</th>
                  <th class="table__header__column">{{ $t('name') }}</th>
                  <th class="table__header__column">{{ $t('firstname')}}</th>
                  <th class="table__header__column">{{ $t('tel')}}</th>
                  <th class="table__header__column">{{ $t('email')}}</th>
                  <th class="table__header__column">{{ $t('street')}}</th>
                  <th class="table__header__column">{{ $t('nr')}}</th>
                  <th class="table__header__column">{{ $t('zip')}}</th>
                  <th class="table__header__column">{{ $t('city')}}</th>
                  <th class="table__header__column">{{ $t('country')}}</th>
                  <th class="table__header__column">Language</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" :class="{'table__body__item--error':item['0errors']}"
                    v-for="(item,index) in uploadListResult.input" :key="'row'+index">
                  <td class="table__body__item__column" v-for="key in Object.keys(item)" :key="index+key">
                    <template v-if="key === '1error_desc' && item['0errors']">
                      <p v-for="error in Object.keys(item[key])" :key="index+error">
                        {{ `${error} => ${item[key][error]}` }}</p>
                    </template>
                    <template v-else>{{ item[key] }}</template>
                  </td>
                </tr>
                </tbody>
              </table>
              <form @submit.prevent="runUploadList" v-else>
                <div class="form__column">
                  <input type="file" accept="*.xlsx" class="file-input file-input--inline" id="INPUTFILE"
                         name="INPUTFILE"
                         @change="uploadSrcChanged($event)"> <span v-if="uploadList">{{ uploadList.name }}</span>
                </div>
              </form>
            </template>
          </div>
          <div class="modal__controls">
            <p class="error" v-if="modalError">{{ modalError }}</p>
            <button class="button button--accept" :class="{'button--disabled':isLoading}" :disabled="isLoading" @click.prevent="runUploadList"
                    v-if="!uploadListResult && !isLoading">
              {{ $t('confirm') }}
            </button>
            <button class="button button--accept" @click.prevent="retryUploadList"
                    v-if="uploadListResult && modalError">
              {{ $t('try-again') }}
            </button>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
export default {
  name: "Employees",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      error: null,
      modalError: null,
      isLoading: false,
      isModalActive: false,
      deleteId: null,
      employee: null,
      isSent:false,

      uploadList: null,
      isUploadActive: false,
      uploadListResult: null,
      search: ""
    }
  },

  created() {
    this.loadInvoices();
    this.loadEmployees();
  },

  computed: {
    employees() {
      return this.$store.getters['employees/employees'];
    },
    hasEmployees() {
      return this.$store.getters['employees/hasEmployees'];
    },
    companySettings(){
      return this.$store.getters['company/companySettings'];
    },
    filteredEmployees(){
      const s = this.search;
      const emps = [...this.employees];
      if(!s){
        return this.employees;
      }
      return emps.filter(l => (`${l.lastname} ${l.firstname}`).toLowerCase().match(s.toLowerCase()) || (`${l.firstname} ${l.lastname}`).toLowerCase().match(s.toLowerCase()));
    },
    invoices(){
      return this.$store.getters['company/invoices'];
    },
    hasInvoices(){
      return this.$store.getters['company/hasInvoices'];
    }
  },

  methods: {
    async loadInvoices(){
      try {
        await this.$store.dispatch('company/getInvoiceAdresses');
      } catch (e) {
        alert(e);
      }
    },
    async loadEmployees() {
      this.error = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('employees/loadEmployees');
      } catch (error) {
        this.error = error.message || this.$t('error.general');
      }
      this.isLoading = false;
    },
    newEmployee() {
      this.employee = {
        firstname: "",
        lastname: "",
        username: "",
        street: "",
        nr: "",
        zip: "",
        city: "",
        country: "BE",
        email: "",
        tel: "",
        invoice_id:null,
        ext_ref:""
      };
      this.isModalActive = true;
    },
    editEmployee(item) {
      this.employee = JSON.parse(JSON.stringify(item));
      this.isModalActive = true;
    },
    deleteEmployee(id) {
      this.deleteId = id;
      this.isModalActive = true;
    },
    async deleteConfirm() {
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('employees/deleteEmployee', {id: this.deleteId});
        this.closeModal();
        this.loadEmployees();
      } catch (error) {
        this.modalError = error.message || this.$t('error.employees.delete');
        this.isLoading = false;
      }
    },
    saveEmployee() {
      const empl = this.employee;
      if (!empl.firstname || !empl.lastname || !empl.email || (this.hasInvoices && !empl.invoice_id)) {
        this.modalError = this.$t('error.employees.incomplete');
        return;
      }
      if (empl.id) {
        this.saveEditEmployee(empl);
        return;
      }
      this.saveNewEmployee(empl);
    },
    async saveEditEmployee(empl) {
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('employees/editEmployee', empl);
        this.closeModal();
        this.loadEmployees();
      } catch (error) {
        this.modalError = error.message || this.$t('error.employees.edit');
        this.isLoading = false;
      }
    },
    async saveNewEmployee(empl){
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('employees/addEmployee', empl);
        this.closeModal();
        this.loadEmployees();
      } catch (error) {
        this.modalError = error.message || this.$t('error.locations.save');
        this.isLoading = false;
      }
    },
    closeModal(){
      this.deleteId = null;
      this.isModalActive = false;
      this.modalError = null;
      this.uploadList = null;
      this.uploadListResult = null;
      this.isUploadActive = false;
    },
    async sendMail(empl){
      const res = await this.$store.dispatch('employees/sendPasswordEmployee',empl.id);
      if(!res){
        return;
      }
      this.isSent = empl.id;
      setTimeout(()=>{
        this.isSent = false;
      },500)
    },

    uploadEmployeeList(){
      this.uploadList = null;
      this.isUploadActive = true;
      this.uploadListResult = null;
    },

    uploadSrcChanged(input) {
      if (input.target.files && input.target.files[0]) {
        this.uploadList = input.target.files[0];
      }
    },

    async runUploadList() {
      if (!this.uploadList) {
        return;
      }
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('employees/uploadEmployeeList', {
          INPUTFILE: this.uploadList
        });
        this.uploadListResult = response;
        if(!response.input){
          this.modalError=this.$t('error.empty');
        }
        else if (response.input.some(i => i["0errors"])) {
          this.modalError = this.$t('error.invalid');
        } else {
          this.loadEmployees();
        }
      } catch (error) {
        this.modalError = error.message || this.$t('error.general');
      }
      this.isLoading = false;
    },
    retryUploadList() {
      this.uploadList = null;
      this.uploadListResult = null;
      this.modalError = null;
    }
  }
}
</script>