<template>
  <Language class="login__language"></Language>
  <img class="login__logo" src="../assets/image/logo-billo-work.svg" alt="logo Billo 2 work">
  <div class="login">
    <h1>{{ $t('passwordrequest.title') }}</h1>
    <p class="success" v-if="isSent">{{$t('passwordrequest.sent')}}</p>
    <form @submit.prevent="submitForm" v-else>
      <div>
        <label for="username">{{ $t('login.username') }}</label>
        <input class="input input--login" :placeholder="$t('login.username')" type="text" id="username"
               v-model.trim="username"/>
      </div>
      <button class="button">{{ $t('passwordrequest.request') }}</button>
    </form>
    <p class="error" v-if="!formIsValid">{{ $t('error.passwordrequest.invalid') }}</p>
    <p class="error" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import Language from "@/components/Language";

export default {
  name: "PasswordRequest",
  components: {
    Language
  },
  data() {
    return {
      error: null,
      username: "",
      formIsValid: true,
      isSent: false
    };
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if(!this.username){
        this.formIsValid = false;
        return;
      }
      try {
        await this.$store.dispatch('resetPasswordUsername', this.username);
        this.isSent = true;
      } catch(e){
        this.error = e;
      }
    }
  }
}
</script>