import nl from './nl.json';
import fr from './fr.json';
import en from './en.json';

export const defaultLocale = _getLocale();

export const languages = {
    nl,
    fr,
    en
};

function _getLocale(){
    const lang = navigator.language;
    if(lang.toLowerCase().match('nl')){
        return 'nl';
    }
    else if(lang.toLowerCase().match('fr')) {
        return 'fr';
    }
    return 'en';
}