let timer;
export default {
    async login(context, payload) {
        return context.dispatch('auth', payload);
    },
    async auth(context, payload) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}${process.env.VUE_APP_ROOT_API_AUTH}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'password',
                scope: 'billo2workadmin',
                code: 'code',
                username: payload.username,
                password: payload.password,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.error_description || 'Failed to authenticate. Check your login data.'
            );
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('userName', payload.username);
        localStorage.setItem('userId', responseData.user_id);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function () {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userId: responseData.user_id,
            userName: payload.username
        });
        this.dispatch('company/loadCompany');
    },

    async refreshToken(context) {
        const userId = localStorage.getItem('userId');
        const userName = localStorage.getItem('userName');
        const refreshtoken = localStorage.getItem('refreshToken');
        if (!refreshtoken || !userId || !userName) {
            context.dispatch('autoLogout');
            return;
        }
        let url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}${process.env.VUE_APP_ROOT_API_AUTH}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'refresh_token',
                refresh_token: refreshtoken,
                scope: 'billo2workadmin',
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.message || 'Failed to re-authenticate.'
            );
            context.dispatch('autoLogout');
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function () {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userId: userId,
            userName: userName
        });
        this.dispatch('company/loadCompany');
    },
    tryLogin(context) {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        const userId = localStorage.getItem('userId');
        const userName = localStorage.getItem('userName');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        const expiresIn = +tokenExpiration - new Date().getTime();

        if (!refreshToken) {
            return;
        }

        if ((!expiresIn || expiresIn < 0) && refreshToken) {
            context.dispatch('refreshToken');
            return;
        }

        timer = setTimeout(function () {
            context.dispatch('refreshToken');
        }, expiresIn);

        if (token && userId) {
            context.commit('setUser', {
                token: token,
                userId: userId,
                userName: userName
            });
        }
        this.dispatch('company/loadCompany');
    },
    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setUser', {
            token: null,
            userId: null,
            userName: null
        });
    },
    autoLogout(context) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    },

    async resetPasswordUsername(context, payload) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/resetpasswordusername?username=${payload}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || responseData.error || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to request new password.'
            );
            throw error;
        }
    },

    async checkPasswordResetValid(context, payload) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/passwordresetvalid?username=${payload.username}&code=${payload.code}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || responseData.error || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Invalid/expired password reset request.'
            );
            throw error;
        }
    },

    async editPassword(context, payload) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/changepassword`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || responseData.error || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Error changing password.'
            );
            throw error;
        }
        this.dispatch('login',{username:payload.username,password:payload.password});
    }
};