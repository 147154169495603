<template>
  <TheHeader v-if="isAuthenticated"></TheHeader>
  <router-view/>
  <TheFooter v-if="isAuthenticated"></TheFooter>
</template>
<script>

import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
export default {
  components:{
    TheHeader,
    TheFooter
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },
  created() {
    this.$store.dispatch('tryLogin');
  },
  watch: {
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/');
      }
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/app';
</style>
