export default{
    async loadCompany(context){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/companydata/${userId}`;
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user company.'
            );
            throw error;
        }
        context.commit('setCompany',responseData.data);
    },
    async editCompany(context, data){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;

        data.user_id=userId;

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/editcompany`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit company.'
            );
            throw error;
        }
    },

    async getInvoiceAdresses(context){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/invoiceaddresses/${userId}`;
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user invoice addresses.'
            );
            throw error;
        }
        context.commit('setInvoices',responseData.data);
    },
    async addInvoice(context, payload){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        payload.user_id = userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/addInvoiceAddress`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body:JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to add invoice address.'
            );
            throw error;
        }

    },
    async editInvoice(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/editInvoiceAddress`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body:JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit invoice address.'
            );
            throw error;
        }
    },
    async deleteInvoice(context, payload){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        payload.user_id = userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/company/deleteInvoiceAddress`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body:JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to delete invoice address.'
            );
            throw error;
        }
    },

    async getReport(context, {customer,from,to}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/historiek/printrittenscherm`;
        const datum = [from,to];
        const payload = {
            table : "HISTORIEKRITTEN",
            linkklant: customer,
            datum
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to print historiek ritten.'
            );
            throw error;
        }
        return responseData.data;
    }
}