<template>
  <footer class="footer">
    Billo2Work Admins | v {{version}}
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      version: process.env.VERSION
    }
  }
}
</script>