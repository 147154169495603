<template>
  <div class="page locations">
    <h1>{{ $t('locations.title') }}</h1>
    <div class="locations__controls">
      <label for="search">{{ $t('search') }}</label><input type="text" class="input" v-model="search">
      <button class="button" :class="{'button--accept':activeFilter==='pickup'}" @click="toggleFilter('pickup')">Pickup</button>
      <button class="button" :class="{'button--accept':activeFilter==='corporate'}" @click="toggleFilter('corporate')">Corporate</button>
      <button class="button" @click="isOverview=true">Map</button>
      <button v-if="companySettings.managelocations" class="button button--accept" @click="newLocation">
        {{ $t('locations.add-location') }} +
      </button>
    </div>
    <div class="locations__results">
      <teleport to="#app">
        <div class="modal" v-if="isLoading">
          <fa icon="spinner" class="loader"></fa>
        </div>
      </teleport>
      <table class="table" v-if="hasLocations && filteredLocations.length">
        <thead>
        <tr class="table__header">
          <th class="table__header__column">{{ $t('actions') }}</th>
          <th class="table__header__column">ID</th>
          <th class="table__header__column">{{ $t('name') }}</th>
          <th class="table__header__column">{{ $t('locations.type') }}</th>
          <th class="table__header__column">Route</th>
          <th class="table__header__column">Step</th>
          <th class="table__header__column">{{ $t('street') }}</th>
          <th class="table__header__column">{{ $t('nr') }}</th>
          <th class="table__header__column">{{ $t('zip') }}</th>
          <th class="table__header__column">{{ $t('city') }}</th>
          <th class="table__header__column">{{ $t('country') }}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr class="table__body__item" v-for="item in filteredLocations" :key="item.id">
          <td class="table__body__item__column">
            <button class="svgbutton info" :title="$t(companySettings.managelocations?'locations.edit-location':'locations.view-title')" @click="editLocation(item)">
              <fa :icon="companySettings.managelocations?'edit':'eye'"></fa>
            </button>
            <button class="svgbutton info" :title="$t('locations.show-location')" @click="locationItem = item">
              <fa icon="map"></fa>
            </button>
            <button class="svgbutton close" :title="$t('locations.delete-location')" @click="deleteLocation(item.id)"
                    v-if="companySettings.managelocations">
              <fa icon="trash-alt"></fa>
            </button>
          </td>
          <td class="table__body__item__column">{{ item.id }}</td>
          <td class="table__body__item__column">{{ item.naam }}</td>
          <td class="table__body__item__column">{{ item.soort }}</td>
          <td class="table__body__item__column">{{ item.route }}</td>
          <td class="table__body__item__column">{{ item.step }}</td>
          <td class="table__body__item__column">{{ item.straat }}</td>
          <td class="table__body__item__column">{{ item.nummer }}</td>
          <td class="table__body__item__column">{{ item.postnr }}</td>
          <td class="table__body__item__column">{{ item.stad }}</td>
          <td class="table__body__item__column">{{ item.land }}</td>
        </tr>
        </tbody>
      </table>
      <div v-else class="no_results">
        {{ $t('locations.no-locations') }}
      </div>
    </div>
    <teleport to="#app">
      <div class="modal" v-if="isModalActive">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="closeModal">
            <fa icon="times"></fa>
          </button>
          <template v-if="deleteId">
            <div class="modal__title">{{ $t('locations.delete-title') }}</div>
            <div class="modal__body">{{ $t('locations.delete-text') }}</div>
            <div class="modal__controls">
              <button class="button button--cancel" :class="{'button--disabled':isLoading}" :disabled="isLoading"
                      @click="deleteConfirm">{{ $t('delete') }}
              </button>
            </div>
          </template>
          <template v-else>
            <div class="modal__title" v-if="companySettings.managelocations">
              {{ address.id ? $t('locations.edit-title') : $t('locations.add-title') }}
            </div>
            <div class="modal__title" v-else>{{ $t('locations.view-title') }}</div>
            <div class="modal__body">
              <form action="" @submit.prevent="saveAddress">
                <div class="form__column">
                  <div class="form__field">
                    <label for="a-name">{{ $t('name') }}</label>
                    <input name="a-name" :placeholder="$t('name')" id="a-name" type="text" class="input"
                           :readonly="!companySettings.managelocations || !!address.fixedgps"
                           v-model="address.naam">
                  </div>
                  <div class="form__field">
                    <label for="a-street">{{ $t('street') }}</label>
                    <input name="a-street" :placeholder="$t('street')" id="a-street" type="text" class="input"
                           :readonly="!companySettings.managelocations || !!address.fixedgps"
                           v-model="address.straat">
                  </div>
                  <div class="form__field">
                    <label for="a-nummer">{{ $t('nr') }}</label>
                    <input name="a-nummer" :placeholder="$t('nr')" id="a-nummer" type="text" class="input"
                           :readonly="!companySettings.managelocations || !!address.fixedgps"
                           v-model="address.nummer">
                  </div>
                  <div class="form__field">
                    <label for="a-postnr">{{ $t('zip') }}</label>
                    <input name="a-postnr" :placeholder="$t('zip')" id="a-postnr" type="text" class="input"
                           :readonly="!companySettings.managelocations || !!address.fixedgps"
                           v-model="address.postnr">
                  </div>
                  <div class="form__field">
                    <label for="a-stad">{{ $t('city') }}</label>
                    <input name="a-stad" :placeholder="$t('city')" id="a-stad" type="text" class="input"
                           :readonly="!companySettings.managelocations || !!address.fixedgps"
                           v-model="address.stad">
                  </div>
                  <div class="form__field">
                    <label for="a-land">{{ $t('country') }}</label>
                    <select :disabled="!companySettings.managelocations || !!address.fixedgps" name="a-land" id="a-land" class="select"
                            v-model="address.land">
                      <option value="BE">Belgium</option>
                      <option value="FR">France</option>
                      <option value="NL">Netherlands</option>
                      <option value="LU">Luxembourg</option>
                      <option value="DE">Germany</option>
                    </select>
                  </div>
                  <div class="form__field">
                    <label for="fixed">Vaste coördinaten</label>
                    <input type="checkbox" :disabled="!companySettings.managelocations" id="fixed" name="fixed" v-model="address.fixedgps">
                  </div>
                  <div class="form__field">
                    <label>Lat</label>
                    <input type="text" class="input" v-model="address.lat" :readonly="!address.fixedgps || !companySettings.managelocations">
                  </div>
                  <div class="form__field">
                    <label>Lon</label>
                    <input type="text" class="input" v-model="address.lon" :readonly="!address.fixedgps || !companySettings.managelocations">
                  </div>
                </div>
                <div class="form__column">
                  <div class="radio">
                    <input type="radio" id="pickup" value="pickup" v-model="address.soort"
                           :disabled="!companySettings.managelocations">
                    <label for="pickup">{{ $t('locations.pickup') }}</label>
                  </div>
                  <div class="radio">
                    <input type="radio" id="dropoff" value="corporate" v-model="address.soort"
                           :disabled="!companySettings.managelocations">
                    <label for="dropoff">{{ $t('locations.workspace') }}</label>
                  </div>
                    <p><b>{{ $t('locations.pickuptimes') }}</b></p>
                    <input type="time" class="input" v-model="pTime" step="300" v-if="companySettings.managelocations">
                    <button v-if="companySettings.managelocations" class="button button--accept"
                            @click.prevent="addTimeP">+
                    </button>
                    <p v-if="!address.urenHeen.length">{{ $t('locations.nopickuptimes') }}</p>
                    <p v-else><span class="time" v-for="item in address.urenHeen.sort()"
                                    :key="`pTime--${item}`">{{ item }} <button class="svgbutton"
                                                                               @click="removeTimeP(item)"
                                                                               v-if="companySettings.managelocations"><fa
                        icon="times"></fa></button></span></p>
                    <p><b>{{ $t('locations.dropofftimes') }}</b></p>
                    <input type="time" class="input" v-model="dTime" step="300" v-if="companySettings.managelocations">
                    <button class="button button--accept" @click.prevent="addTimeD"
                            v-if="companySettings.managelocations">+
                    </button>
                    <p v-if="!address.urenTerug.length">{{ $t('locations.nodropofftimes') }}</p>
                    <p v-else><span class="time" v-for="item in address.urenTerug.sort()"
                                    :key="`dTime--${item}`">{{ item }} <button class="svgbutton"
                                                                               @click="removeTimeD(item)"
                                                                               v-if="companySettings.managelocations"><fa
                        icon="times"></fa></button></span></p>
                  <div class="form__field">
                    <label for="route">Route</label>
                    <input type="number" id="route" class="input input--small" v-model.number="address.route" :readonly="!companySettings.managelocations">
                  </div>
                  <div class="form__field">
                    <label for="pos">Route position</label>
                    <input type="number" id="pos" class="input input--small" v-model.number="address.step" :readonly="!companySettings.managelocations">
                  </div>
                </div>
              </form>
            </div>
            <div class="modal__controls" v-if="companySettings.managelocations">
              <p class="error" v-if="modalError">{{ modalError }}</p>
              <button class="button button--accept" :class="{'button--disabled':isLoading}" :disabled="isLoading"
                      @click.prevent="saveAddress">{{ $t('save') }}
              </button>
            </div>
          </template>
        </div>
      </div>
      <div class="modal" v-if="locationItem">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="locationItem = null">
            <fa icon="times"></fa>
          </button>
          <div class="modal__body">
            <p><b>{{ locationItem.naam }}</b><br>{{ locationItem.straat }} {{ locationItem.nummer }},
              {{ locationItem.postnr }} {{ locationItem.stad }}</p>
            <GoogleMarker :data="locationItem"/>
          </div>
        </div>
      </div>
      <div class="modal" v-if="isOverview">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="isOverview=false">
            <fa icon="times"></fa>
          </button>
          <div class="modal__body">
            <GoogleOverview :data="locations" :manage="companySettings.managelocations" />
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import GoogleMarker from "@/components/GoogleMarker";
import GoogleOverview from "@/components/GoogleOverview";

export default {
  name: "Locations",
  components: {GoogleOverview, GoogleMarker},
  data() {
    return {
      activeFilter:null,
      error: null,
      modalError: null,
      isLoading: false,
      isModalActive: false,
      locationItem: null,
      deleteId: null,
      pTime: "00:00",
      dTime: "00:00",
      search: "",
      address: null,
      isOverview: false
    }
  },

  created() {
    this.loadLocations();
  },
  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },
    hasLocations() {
      return this.$store.getters['locations/hasLocations'];
    },
    companySettings() {
      return this.$store.getters['company/companySettings'];
    },
    filteredLocations() {
      const s = this.search;
      let locs = [...this.locations];
      const af = this.activeFilter;
      if(af){
        locs = locs.filter(l => l.soort === af);
      }
      if (!s) {
        return locs;
      }
      return locs.filter(l => l.naam.toLowerCase().match(s.toLowerCase()) || l.id.toString().match(s.toLowerCase()));
    }
  },

  methods: {
    async loadLocations() {
      this.error = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/loadLocations');

      } catch (error) {
        this.error = error.message || this.$t('error.general');
      }
      this.isLoading = false;
    },
    newLocation() {
      this.address = {
        naam: "",
        straat: "",
        nummer: "",
        postnr: "",
        stad: "",
        soort: "",
        land: "BE",
        urenHeen: [],
        urenTerug: [],
        fixedgps: false,
        lat: "",
        lon: "",
        route: "",
        step: ""
      }
      this.isModalActive = true;
    },
    editLocation(item) {
      this.address = JSON.parse(JSON.stringify(item));
      this.isModalActive = true;
    },

    deleteLocation(id) {
      this.deleteId = id;
      this.isModalActive = true;
    },

    async deleteConfirm() {
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/deleteLocation', {id: this.deleteId});
        this.closeModal();
        this.loadLocations();
      } catch (error) {
        this.modalError = error.message || this.$t('error.locations.delete');
        this.isLoading = false;
      }
    },

    saveAddress() {
      const adr = this.address;
      if (!adr.naam
          || !adr.straat
          || !adr.nummer
          || !adr.postnr
          || !adr.stad
          || !adr.land
          || !adr.soort
          || (adr.soort === 'pickup' && !adr.urenHeen.length)
          || (adr.soort === 'corporate' && !adr.urenTerug.length)
          || adr.fixedgps && (!adr.lat || !adr.lon)) {
        this.modalError = this.$t('error.locations.incomplete');
        return;
      }
      if (adr.id) {
        this.saveEditLocation(adr);
        return;
      }
      this.saveNewLocation(adr);
    },
    async saveEditLocation(adr) {
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/editLocation', adr);
        this.closeModal();
        this.loadLocations();
      } catch (error) {
        this.modalError = error.message || this.$t('error.locations.edit');
        this.isLoading = false;
      }
    },
    async saveNewLocation(adr) {
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/addLocation', adr);
        this.closeModal();
        this.loadLocations();
      } catch (error) {
        this.modalError = error.message || this.$t('error.locations.save');
        this.isLoading = false;
      }

    },

    closeModal() {
      this.deleteId = null;
      this.isModalActive = false;
      this.modalError = null;
    },

    addTimeP() {
      if (!this.pTime || this.address.urenHeen.indexOf(this.pTime) > -1) {
        return;
      }
      this.address.urenHeen.push(this.pTime);
    },
    addTimeD() {
      if (!this.dTime || this.address.urenTerug.indexOf(this.dTime) > -1) {
        return;
      }
      this.address.urenTerug.push(this.dTime);
    },
    removeTimeP(time) {
      const delId = this.address.urenHeen.indexOf(time);
      this.address.urenHeen.splice(delId, 1);
    },
    removeTimeD(time) {
      const delId = this.address.urenTerug.indexOf(time);
      this.address.urenTerug.splice(delId, 1);
    },

    toggleFilter(filter){
      if(this.activeFilter === filter){
        this.activeFilter = null;
        return;
      }
      this.activeFilter = filter;
    }
  }
}
</script>