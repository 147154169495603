<template>
  <div class="user"><span>{{ user}}</span></div>
</template>

<script>
export default {
name: "User",
  computed: {
   user() {
      return `${this.$store.getters.userName} (id: ${this.$store.getters.userId})`;
    }
  }
}
</script>