<template>
  <div class="language">
    <button class="language__btn">{{$i18n.locale}}</button>
    <div class="language__content">
      <div class="language__content__lang"
           v-for="item in $i18n.availableLocales.filter(i=>i!==$i18n.locale)" :key="item" @click="$i18n.locale=item">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Language"
}
</script>