export default{
    company(state){
        return state.company;
    },
    companySettings(state){
        return state.company?state.company.settings:{};
    },

    invoices(state){
        return state.invoices;
    },
    hasInvoices(state){
        return state.invoices && state.invoices.length;
    }
}