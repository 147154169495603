import { createStore } from 'vuex'
import authModule from './modules/auth';
import locationModule from './modules/locations';
import employeeModule from './modules/employees';
import companyModule from './modules/company';

export default createStore({
  modules: {
    auth: authModule,
    locations: locationModule,
    employees: employeeModule,
    company: companyModule
  }
})
