<template>
  <div class="header">
    <div class="header__container">
      <div class="header__nav">
        <router-link to="/locations">{{ $t('locations.title') }}</router-link>
        <router-link to="/employees">{{ $t('employees.title') }}</router-link>
        <router-link to="/company">{{ $t('company.title') }}</router-link>
        <button @click="showHistory">{{ $t('history.title') }}</button>
      </div>
      <div class="header__controls">
        <Language class="header__controls__language"></Language>
        <User class="header__controls__user"></User>
        <button class="header__controls__logout svgbutton logout" title="log out" @click="logout">
          <fa icon="power-off"></fa>
        </button>
      </div>
    </div>
    <teleport to="#app">
      <div class="modal" v-if="historyVisible">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="closeHistory">
            <fa icon="times"></fa>
          </button>
          <div class="modal__title">
            {{ $t('history.popup') }}
          </div>
          <div class="modal__body">
            <form action="" @submit.prevent="getHistory">
              <div class="form__column">
                <div class="form__field">
                  <label>{{ $t('history.from') }}</label>
                  <input type="date" class="input" v-model="vDate" :readonly="loading">
                </div>
                <div class="form__field">
                  <label>{{ $t('history.to') }}</label>
                  <input type="date" class="input" v-model="tDate" :readonly="loading">
                </div>
              </div>
            </form>
          </div>
          <div class="modal__controls">
            <button class="button" :class="{'button--disabled':loading}" @click="getHistory" v-if="vDate && tDate">{{ $t('history.request') }}</button>
            <a :href="historyData.pdf" target="_blank" class="button button--accept" :class="{'button--disabled':loading}"  v-if="historyData && historyData.pdf">{{ $t('open') }}</a>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import User from "@/components/User";
import Language from "@/components/Language";

export default {
  name: "TheHeader",
  data(){
    return {
      historyVisible: false,
      historyData: null,
      loading: false,
      vDate: "",
      tDate: ""
    }
  },
  components: {
    User,
    Language
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    },
    showHistory(){
      this.historyVisible = true;
    },
    async getHistory(){
      if(!this.vDate || !this.tDate){
        return;
      }
      this.historyData = null;
      this.loading = true;
      const vDate = this.vDate.replaceAll('-','/');
      const tDate = this.tDate.replaceAll('-','/');
      try{
        this.historyData = await this.$store.dispatch('company/getReport',{customer:52120,from:vDate,to:tDate});
      }catch(e){
        alert(e);
      }
      this.loading = false;
    },
    closeHistory(){
      this.historyVisible = false;
      this.historyData = null;
    }
  }
}
</script>