<template>
  <div class="google-route" id="google_route"></div>
</template>

<script>
import MapsPlugin from '@/plugins/maps-plugin';

export default {
  name: "GoogleOverview",
  props: {
    data: {required: true},
    manage:{}
  },
  data() {
    return {
      markers: [],
      infowindows: [],
      routes: [],
      selection: [],
      markerP: {
        path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        fillColor: "#FAD701",
        strokeColor: "black",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0,
        scale: 0.8
      },
      markerC: {
        path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        fillColor: "black",
        strokeColor: "#FAD701",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0,
        scale: 0.8
      },
      markerSelect: {
        path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        fillColor: "red",
        strokeColor: "red",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0,
        scale: 0.8
      }
    }
  },
  mounted() {
    MapsPlugin.checkMapsLoaded(this.initMap)
  },
  beforeUnmount() {
    this.markers.forEach(m => {
      m.setMap(null);
      m = null;
    });
    this.infowindows.forEach(i => {
      i.setMap(null);
      i = null;
    })
  },
  methods: {
    initMap() {
      const data = this.data;
      const routes = [...new Set(data.map(item => item.route))];
      routes.forEach(r => this.routes.push({route: r, color: r===0?'#FAD701':'#' + Math.floor(Math.random() * 16777215).toString(16)}))
      const map = new google.maps.Map(document.getElementById('google_route'), {
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: {lat: 51.24179, lng: 4.38146}
      });

      data.forEach(m => {
        const latlng = {lat: parseFloat(m.lat), lng: parseFloat(m.lon)}
        const icon = JSON.parse(JSON.stringify(this.markerP));
        if (m.route) {
          icon.fillColor = this.routes.find(r => r.route === m.route).color;
        }
        const marker = new google.maps.Marker({
          id: m.id,
          position: latlng,
          title: m.naam,
          icon: m.soort === 'corporate' ? this.markerC : icon,
          map
        });
        const contentString = `<p>${m.id}</p><p><b>${m.naam}</b><br>${m.soort}</p><p>${m.straat} ${m.nummer}<br>${m.postnr} ${m.stad}</p>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        marker.addListener("click", () => {
          infowindow.open(map, marker);
        });
        if(this.manage) {
          marker.addListener("rightclick", async () => {
            if (m.soort === 'corporate') {
              return;
            }
            const route = parseInt(prompt("Route", m.route));
            const step = parseInt(prompt("Step", m.step));
            if (Number.isNaN(route) || Number.isNaN(step) || route === null || step === null) {
              return;
            }
            try {
              await this.$store.dispatch('locations/changeRoute', {id: m.id, route, step});
              m.route = route;
              m.step = step;
              const icon = JSON.parse(JSON.stringify(this.markerP));
              const exRoute = this.routes.find(r => r.route === route);
              if (exRoute) {
                icon.fillColor = exRoute.color;
              } else if (route !== 0) {
                const newColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
                this.routes.push({route: route, color: newColor});
                icon.fillColor = newColor;
              }
              marker.setIcon(icon);

            } catch (e) {
              alert(e);
            }
          });
        }
        this.markers.push(marker);
        this.infowindows.push(infowindow);
      });
    }
  }
}
</script>