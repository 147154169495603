import {createRouter, createWebHashHistory} from 'vue-router'
import Employees from '@/views/Employees';
import Locations from '@/views/Locations';
import Company from "@/views/Company";
import Login from '@/views/Login';
import store from '@/store';
import PasswordRequest from "@/views/PasswordRequest";
import PasswordReset from "@/views/PasswordReset";

const routes = [
    {
        path: '/',
        redirect: '/locations',
    },
    {
        path: '/locations',
        name: 'Locations',
        component: Locations,
        meta: {requiresAuth: true}
    },
    {
        path: '/employees',
        name: 'Employees',
        component: Employees,
        meta: {requiresAuth: true}
    },
    {
        path: '/company',
        name: 'Company',
        component: Company,
        meta: {requiresAuth: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {requiresUnauth: true}
    },
    {
        path: '/password-request',
        name: 'PasswordRequest',
        component: PasswordRequest,
        meta: {requiresUnauth: true}
    },
    {
        path: '/reset-password',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {requiresUnauth: true}
    },
    {
        path: '/:notFound(.*)',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
        next('/');
    } else {
        next();
    }
});

export default router
